import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Game from './game.js'
import './index.css';
import targetWords from './targetWords.json';

var seedrandom = require('seedrandom');
const globalAttempts = 11;
const globalWordLength = 5;
const globalSolution = 'roars';

class HelpDialog extends React.Component {
  render() {
    const isOpen = this.props.open;
    return (
      <dialog
        open={isOpen}
        id="helpDialog"
      >
        <h2>How to play</h2>
        <p>Guess the word before you run out of tries.</p>
        <p>You can only guess valid 5 letter words.</p>
        <p>After you press <b>⏎</b> you get feedback for each letter. For example:</p>
        <div className="gameboard">
          <div className="gameboard-row">
            <button className="square wrong">s</button>
            <button className="square misplaced">t</button>
            <button className="square correct">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">e</button>
          </div>
        </div>
        <ul>
          <li><b>a</b> and <b>r</b> are in the word and in the correct places.</li>
          <li><b>t</b> is in the word but the wrong place.</li>
          <li><b>s</b> and <b>e</b> are nowhere in the word.</li>
          <li><i>But the feedback for one of the letters is a LIE!</i></li>
        </ul>
        <div className="gameboard">
          <div className="gameboard-row">
            <button className="square wrong">s</button>
            <button className="square misplaced">t</button>
            <button className="square correct">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">e</button>
          </div>
          <div className="gameboard-row">
            <button className="square correct">c</button>
            <button className="square correct">h</button>
            <button className="square misplaced">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">t</button>
          </div>
        </div>
        <p>After a second guess, it seems that <b>t</b> should not be in the word at all, and now it appears that the <b>a</b> may belong in a different place. But <b>c</b> and <b>h</b> were lucky guesses for the first two letters.</p>
        <p>If you think that the feedback contained lies in the second letter of the first guess and the third letter of the second guess, you can tap those letters to help remember.</p>
        <div className="gameboard">
          <div className="gameboard-row">
            <button className="square wrong">s</button>
            <button className="square misplaced suspect">t</button>
            <button className="square correct">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">e</button>
          </div>
          <div className="gameboard-row">
            <button className="square correct">c</button>
            <button className="square correct">h</button>
            <button className="square misplaced suspect">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">t</button>
          </div>
        </div>
        <p>Under this hypothesis, you can make another guess.</p>
        <div className="gameboard">
          <div className="gameboard-row">
            <button className="square wrong">s</button>
            <button className="square misplaced suspect">t</button>
            <button className="square correct">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">e</button>
          </div>
          <div className="gameboard-row">
            <button className="square correct">c</button>
            <button className="square correct">h</button>
            <button className="square misplaced suspect">a</button>
            <button className="square correct">r</button>
            <button className="square wrong">t</button>
          </div>
          <div className="gameboard-row">
            <button className="square correct">c</button>
            <button className="square correct">h</button>
            <button className="square correct">a</button>
            <button className="square correct">r</button>
            <button className="square correct">m</button>
          </div>
        </div>
        <p>If your guess is completely correct, then WordLies will tell you so. WordLies will only tell you your guess is completely correct if it truly is.</p>
        <p>You might have to scroll to see all your guesses.</p>
        <p>Your guesses have to be valid 5 letter words</p>
        <div className="gameboard">
          <div className="gameboard-row">
            <button className="square misspelled">t</button>
            <button className="square misspelled">s</button>
            <button className="square misspelled">a</button>
            <button className="square misspelled">r</button>
            <button className="square misspelled">e</button>
          </div>
        </div>
        <p>but you can always use <b>⌫</b> to edit.</p>
      </dialog>
    );
  }
}

class HelpButton extends React.Component {
  render() {
    return (
      <div className="helpbuttondiv">
        <button
          onClick={this.props.onClick}
          className="helpbutton"
        >
          ?
        </button>
      </div>
    );
  }
}

class InfoDialog extends React.Component {
  render() {
    const isOpen = this.props.open;
    return (
      <dialog
        open={isOpen}
        id="infoDialog"
      >
        <h2>About</h2>
        <p>WordLies is a game made by <a href="https://portfolio.dasmithmaths.com/">Dave Smith</a> and inspired by The New York Times' <a href="https://www.nytimes.com/games/wordle/index.html">Wordle</a>.</p>
      </dialog>
    );
  }
}

class InfoButton extends React.Component {
  render() {
    return (
      <div className="infobuttondiv">
        <button
          onClick={this.props.onClick}
          className="infobutton"
        >
          ⓘ
        </button>
      </div>
    );
  }
}

class SettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
      gameMode: this.props.gameMode,
      hasChanged: false,
    });
  }

  handleChangeGameType(e) {
    const newGameMode = e.target.value;
    let hasChanged = false;
    for (const s of ['gameMode']) {
      if (s==='gameMode') {
        if (newGameMode!==this.props[s]) { hasChanged = true; }
        continue;
      }
      if (this.state[s]!==this.props[s]) { hasChanged = true; }
    }
    this.setState({gameMode: newGameMode, hasChanged: hasChanged,});
  }

  render() {
    const isOpen = this.props.open;
    return (
      <dialog
        open={isOpen}
        id="settingsDialog"
      >
        <h2>Settings</h2>
        <form onSubmit={(e) => this.props.onClickSettings(e,this.state.hasChanged,this.state)}>
          <label>
            Game type:
            <select value={this.state.value} onChange={(e) => this.handleChangeGameType(e)}>
              <option value="Daily">Daily</option>
              <option value="Unlimited">Unlimited</option>
              <option value="Challenge1">Challenge 1</option>
              <option value="Challenge2">Challenge 2</option>
              <option value="Challenge3">Challenge 3</option>
            </select>
          </label>
          <input type="submit" value="Save" />
        </form>
      </dialog>
    );
  }
}

class SettingsButton extends React.Component {
  render() {
    return (
      <div className="settingsbuttondiv">
        <button
          onClick={this.props.onClick}
          className="settingsbutton"
        >
          ☰
        </button>
      </div>
    );
  }
}

class TitleBar extends React.Component {
  render() {
    return (
      <div className="titlebar">
        <div className="titleblock-l">
          <SettingsButton
            onClick={(e) => this.props.onClickSettings(e)}
          />
        </div>
        <div className="titleblock-c">
          WordLies
        </div>
        <div className="titleblock-r">
          <HelpButton
            onClick={() => this.props.onClickHelp()}
          />
          <InfoButton
            onClick={() => this.props.onClickInfo()}
          />
        </div>
      </div>
    );
  }
}

class GameMaker extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const attempts = globalAttempts;
    const wordLength = globalWordLength;
    const gameMode = 'Daily';

    const today = new Date();
    const seed = today.toLocaleDateString('en-UK')+gameMode;
    const solutionAndLiePattern = this.getSolutionAndLiePattern(attempts,wordLength,seed);

    // Delete old cookies
    // THIS IS NOT FINISHED!
    // const cookiesList = this.props.cookies.getAll();
    // console.log(cookiesList);
    // for (const k in cookiesList) {
    //   const cookieDate = k.split('|')[3].slice(0,10);
    //   console.log(k+' '+cookieDate);
    // }

    this.state = ({
      showHelp: false,
      showInfo: false,
      showSettings: false,
      gameMode: gameMode,
      liePattern: solutionAndLiePattern.liePattern,
      attempts: attempts,
      wordLength: wordLength,
      solution: solutionAndLiePattern.solution,
      seed: seed,
    });
  }

  getSolutionAndLiePattern(attempts,wordLength,seed) {
    var seededrng = seedrandom(seed);
    function getSeededRandomInt(max) {
      return Math.floor(seededrng() * max);
    }
    var solution = globalSolution;
    solution = targetWords[getSeededRandomInt(targetWords.length)];
    let liePattern = Array.from(Array(attempts), () => {return new Array(wordLength).fill(0)});
    for (let i=0; i<attempts; i++) {
      const lieLocation = getSeededRandomInt(wordLength);
      const lieOffset = 1+getSeededRandomInt(2);
      liePattern[i][lieLocation] = lieOffset;
    }
    return ({
      solution: solution,
      liePattern: liePattern,
    });
  }

  newGame(attempts,wordLength,gameMode='Unlimited') {
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
    let seed='Unlimited';
    const mode = ['Daily','Challenge1','Challenge2','Challenge3'].indexOf(gameMode);
    if (mode===-1) {
      seed += getRandomInt(targetWords.length*1000);
    } else {
      if (mode>0) {
        attempts = 12 - 2*mode;
      }
      const today = new Date();
      seed = today.toLocaleDateString('en-UK')+gameMode;
    }
    const solutionAndLiePattern = this.getSolutionAndLiePattern(attempts,wordLength,seed);

    this.setState({
      showHelp: false,
      showSettings: false,
      gameMode: gameMode,
      liePattern: solutionAndLiePattern.liePattern,
      attempts: attempts,
      wordLength: wordLength,
      solution: solutionAndLiePattern.solution,
      seed: seed,
    });
  }

  handleClickHelp() {
    const showHelp = this.state.showHelp;
    this.setState({showHelp: !showHelp, showInfo: false, showSettings: false});
  }

  handleClickInfo() {
    const showInfo = this.state.showInfo;
    this.setState({showInfo: !showInfo, showHelp: false, showSettings: false});
  }

  handleClickSettings(e,changed=false, newSettings={}) {
    e.preventDefault();
    const showSettings = this.state.showSettings;
    if (!showSettings) {
      this.setState({showSettings: true, showHelp: false, showInfo: false});
      return;
    }
    if (!changed) {
      this.setState({showSettings: false, showHelp: false, showInfo: false});
      return;
    }
    // update the game state using newGame
    let attempts = newSettings.attempts;
    if (!attempts) { attempts = globalAttempts; }
    let wordLength = newSettings.wordLength;
    if (!wordLength) { wordLength = globalWordLength; }
    let gameMode = newSettings.gameMode;
    if (gameMode === undefined || gameMode === null) {
      this.setState({showSettings: false, showInfo: false, showHelp: false});
      return;
    }
    this.newGame(attempts,wordLength,gameMode);
    return;
  }

  render() {
    const gameId = 'WordLies|'+this.state.wordLength+'|'+this.state.attempts+'|'+this.state.seed;

    return (
      <div className="game-maker">
        <TitleBar
          onClickSettings={(e,c,s) => this.handleClickSettings(e,c,s)}
          onClickHelp={() => this.handleClickHelp()}
          onClickInfo={() => this.handleClickInfo()}
        />
        <HelpDialog
          open={this.state.showHelp}
          onClickHelp={() => this.handleClickHelp()}
        />
        <InfoDialog
          open={this.state.showInfo}
          onClickInfo={() => this.handleClickInfo()}
        />
        <SettingsDialog
          open={this.state.showSettings}
          onClickSettings={(e,c,s) => this.handleClickSettings(e,c,s)}
          gameMode={this.state.gameMode}
        />
        <Game key={gameId}
          attempts={this.state.attempts}
          wordLength={this.state.wordLength}
          solution={this.state.solution}
          liePattern={this.state.liePattern}
          id={gameId}
          cookies={this.props.cookies}
          // gameMode={this.state.gameMode}
          // newGame={(m) => this.newGame(this.state.attempts,this.state.wordLength,m)}
        />
      </div>
    );
  }
}

export default withCookies(GameMaker);
