import React from 'react';
import { instanceOf } from 'prop-types';
import { Cookies } from 'react-cookie';
import './index.css';
import dictionary from './dictionary.json';
import keyboard from './keyboard.json';

class Key extends React.Component {
  render() {
    return (
      <button
        className="keyboard-key"
        onClick={() => this.props.onClick()}
      >
        {this.props.letter}
      </button>
    );
  }
}

class Keyboard extends React.Component {
  renderKey(k) {
    return (
      <Key
        key={k}
        letter={k}
        onClick={() => this.props.onClick(k)}
      />
    );
  }
  
  renderKeyRow(i) {
    let row = [];
    for (let j=0; j<keyboard[i].length; j++) {
      row.push(this.renderKey(keyboard[i][j]));
    }
    return (
      <div className="keyboard-row" key={i}>
        {row}
      </div>
    );
  }

  render() {
    let rows = [];
    for (let i=0; i<keyboard.length; i++) {
      rows.push(this.renderKeyRow(i));
    }
    
    return (
      <div className="keyboard">
        {rows}
      </div>
    );
  }
}

class Square extends React.Component {
  render() {
    let className = 'square';
    const info = this.props.info;
    if (info) { className += (' ' + info); }
    return (
      <button
        className={className}
        onClick={() => this.props.onClick()}
      >
        {this.props.value}
      </button>
    );
  }
}

class Board extends React.Component {
  renderSquare(i,j) {
    let value = this.props.guesses[i][j];
    let info = this.props.feedback[i][j];
    if (!value && (i===0 || this.props.guesses[i-1][0]!==null)) {
      value = this.props.newGuess[j];
      if (this.props.isNotAWord) { info = 'misspelled'; }
    }
    if (this.props.suspect[i][j]) { info += ' suspect'; }
    return (
      <Square
        key={i*100+j}
        value={value}
        onClick={() => this.props.onClick(i,j)}
        info={info}
      />
    );
  }
  
  renderBoardRow(i) {
    let row = [];
    for (let j=0; j<this.props.wordLength; j++) {
      row.push(this.renderSquare(i,j));
    }
    return (
      <div className="gameboard-row" key={i}>
        {row}
      </div>
    );
  }

  render() {
    let rows = [];
    for (let i=0; i<this.props.attempts; i++) {
      rows.push(this.renderBoardRow(i));
    }
    
    return (
      <div className="gameboard">
        {rows}
      </div>
    );
  }
}

export default class Game extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    let guesses = Array.from(Array(this.props.attempts), () => {return new Array(this.props.wordLength).fill(null)});
    const suspect = Array.from(Array(this.props.attempts), () => {return new Array(this.props.wordLength).fill(false)});
    const newGuess = new Array(this.props.wordLength).fill(null);

    const cookies = props.cookies;
    const gameState = cookies.get(props.id);

    if (gameState && gameState.hasOwnProperty('guesses')) {
      guesses = gameState.guesses;
   }

    this.state = ({
      guesses: guesses,
      suspect: suspect,
      newGuess: newGuess,
      isNotAWord: false,
    });
  }

  handleSquareClick(i,j) {
    // Mark the square as suspect
    let attemptsUsed=0;
    while (attemptsUsed<this.props.attempts && this.state.guesses[attemptsUsed][0]!==null) {
      attemptsUsed++;
    }
    if (i>=attemptsUsed) { return; }                          // Do nothing if this square belongs to a guess that is not yet submitted
    let suspect = this.state.suspect;
    suspect[i][j] = !suspect[i][j];
    this.setState({suspect: suspect});
  }

  handleKeyClick(k) {
    function checkWordInDectionary(s) {
      // Check whether s is a word in the dictionary
      return dictionary.includes(s);
    }

    let newGuess = this.state.newGuess.slice();
    if (k==="⏎") {
      // Handle return key
      if (this.state.isNotAWord) return;                      // Do nothing if newGuess is not a dictionary word
      if (newGuess[this.props.wordLength-1]===null) return;   // Do nothing if newGuess is not full
      let guesses = this.state.guesses.slice();
      let i = 0;
      while (i<this.props.attempts && guesses[i][0]!==null) { i++; }
      if (i===this.props.attempts) return;                    // Do nothing if we already used all allowed attemts
      guesses[i] = newGuess;
      newGuess = new Array(this.props.wordLength).fill(null);
      // Update cookie & state
      const today = new Date();
      const cookies = this.props.cookies;
      const gameState = ({
        guesses: guesses,
        edited: today,
      });
      cookies.set(this.props.id, JSON.stringify(gameState), { sameSite: 'strict' });
      this.setState({
        guesses: guesses,
        newGuess: newGuess,
        isNotAWord: false,
      });
    } else if (k==="⌫") {
      // Handle backspace key
      let i = 0;
      while (i<this.props.wordLength && newGuess[i]!==null) { i++; }
      if (i===0) return;                         // Do nothing if the newGuess is already empty
      newGuess[i-1] = null;
      this.setState({
        newGuess: newGuess,
        isNotAWord: false,
      });
    } else {
      // Handle any other key
      let i = 0;
      while (i<this.props.wordLength && newGuess[i]!==null) { i++; }
      if (i===this.props.wordLength) return;     // Do nothing if the newGuess is already full
      newGuess[i] = k;
      let isNotAWord = false;
      if (i===this.props.wordLength-1 && !checkWordInDectionary(newGuess.join(''))) {   // Check whether the newGuess is a word & feedback if not
        isNotAWord = true;
      }
      this.setState({
        newGuess: newGuess,
        isNotAWord: isNotAWord,
      });
    }
  }

  render() {
    function generateFeedback(word,solution) {
      const len = solution.length;
      const feedback = new Array(len).fill(null);
      // const wordArray = new Array(len).from(word);    // word should be passed as an array already, and it should be the same length as the string solution
      const wordArray = word;
      const solutionArray = solution.split('');
      for (let i=0; i<len; i++) {
        if (wordArray[i]===solutionArray[i]) { feedback[i] = 'correct'; }
      }
      // Feedback for misplaced letters
      for (let i=0; i<len; i++) {
        if (feedback[i]==='correct') { continue; }
        const c = wordArray[i];
        if (solutionArray.includes(c)) {
          const cPositionsInSolution = solutionArray.map(x => x===c);
          // const cPositionsInWord = wordArray.map(x => x===c);
          const feedbackAlreadyCorrectThisLetter = feedback.map((x,j) => (x==='correct' && wordArray[j]===c));
          const feedbackAlreadyMisplacedThisLetter = feedback.map((x,j) => (x==='misplaced' && wordArray[j]===c));
          const feedbackAlreadyThisLetter = feedbackAlreadyCorrectThisLetter.map((x,j) => (x || feedbackAlreadyMisplacedThisLetter[j]));
          if (feedbackAlreadyThisLetter.filter(Boolean).length<cPositionsInSolution.filter(Boolean).length) {
            feedback[i] = 'misplaced';
          }
        }
      }
      // Feedback for wrong letters
      for (let i=0; i<len; i++) {
        if (feedback[i]===null) { feedback[i] = 'wrong'; }
      }
      return feedback;
    }

    function lie(allFeedback,liePattern) {
      // Apply the liePattern to allFeedback
      const wordLength = liePattern[0].length;
      const attempts = liePattern.length;
      let attemptsUsed=0;
      while (attemptsUsed<attempts && allFeedback[attemptsUsed][0]!==null) {
        attemptsUsed++;
      }
      const feedbackOptions = ['wrong','misplaced','correct','wrong','misplaced'];
      for (let i=0; i<attemptsUsed; i++) {
        if (allFeedback[i].filter(f => (f!=='correct')).length === 0) { break; }            // If all the feedback is 'correct', then tell the truth
        for (let j=0; j<wordLength; j++) {
          if (liePattern[i][j]) {
            const k = feedbackOptions.indexOf(allFeedback[i][j]);
            let feedbackOptionsHere = feedbackOptions.slice(k+1,k+3);
            // If lying here could make all feedback 'correct', then avoid that
            const fbCut = [...allFeedback[i].slice(0, j), ...allFeedback[i].slice(j + 1)]
            if (fbCut.filter(f => (f!=='correct')).length === 0) {
              const index = feedbackOptionsHere.indexOf('correct');
              if (index>-1) {
                feedbackOptionsHere.splice(index,1);
                const y = feedbackOptionsHere.concat(feedbackOptionsHere);
                feedbackOptionsHere = y;
              }
            }
            allFeedback[i][j] = feedbackOptionsHere[liePattern[i][j]-1];
          }
        }
      }
      return allFeedback;
    }
    
    // Get feedback and decide whether the game has been won or lost
    let allFeedback = Array.from(Array(this.props.attempts), () => {return new Array(this.props.wordLength).fill(null)});
    let i = 0;
    while (i<this.props.attempts && this.state.guesses[i][0]!==null) {
      allFeedback[i] = generateFeedback(this.state.guesses[i],this.props.solution);
      i++;
    }
    allFeedback = lie(allFeedback,this.props.liePattern);
    const won = (allFeedback[Math.max(0,i-1)].filter((f) => (f==='correct')).length===this.props.wordLength);
    const lost = (allFeedback[this.props.attempts-1][0]!==null && !won);
    // TO DO: THE KEYBOARD ALSO NEEDS FEEDBACK

    // Construct the solution board for a game that has been won or lost
    let solutionBoard = '';
    if (lost) {
      solutionBoard = (
        <>
        <div className="lost before">
          <p>Sorry! The solution was</p>
        </div>
        <Board
          attempts={1}
          wordLength={this.props.wordLength}
          guesses={Array.from(Array(1), () => {return this.props.solution.split('')})}
          newGuess={new Array(this.props.wordLength).fill(null)}
          isNotAWord={false}
          feedback={Array.from(Array(1), () => {return new Array(this.props.wordLength).fill('correct')})}
          suspect={Array.from(Array(1), () => {return new Array(this.props.wordLength).fill(false)})}
          onClick={won ? (i,j) => {} : ((i,j) => this.handleSquareClick(i,j))}
        />
        <div className="lost after">
          <p>Refresh the page to try another</p>
        </div>
        </>
      );
    } else if (won) {
      solutionBoard = (
        <div className="won">
          <p>Congratulations! Go to ☰ to try another game type.</p>
        </div>
      );
    }

    return (
      <div className="game">
        <div className="output">
          <div>
            <Board
              attempts={this.props.attempts}
              wordLength={this.props.wordLength}
              guesses={this.state.guesses}
              newGuess={this.state.newGuess}
              isNotAWord={this.state.isNotAWord}
              feedback={allFeedback}
              suspect={this.state.suspect}
              onClick={won ? (i,j) => {} : ((i,j) => this.handleSquareClick(i,j))}
            />
            {solutionBoard}
          </div>
        </div>
        <div className="input">
          <Keyboard
            onClick={won ? (k) => {} : ((k) => this.handleKeyClick(k))}       // If the game is already won then disable the keyboard
          />
        </div>
      </div>
    );
  }
}
