import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import GameMaker from './gamemaker.js'
import './index.css';



// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <GameMaker />
  </CookiesProvider>
);
